import React from "react"
import AuthorIcon from "../assets/svg/author.inline.svg"
import DateIcon from "../assets/svg/date.inline.svg"
import ModifiedIcon from "../assets/svg/modified.inline.svg"
import { getFormattedDate } from "../utils/get-date"

const PostMeta = ({ author, date, modified }) => {
  return (
    <div className="post-meta-wrapper post-meta-single post-meta-single-top">
      <ul className="post-meta">
        <li className="post-author meta-wrapper">
          <span className="meta-icon">
            <span className="screen-reader-text">Author</span>
            <AuthorIcon />
          </span>
          <span className="meta-text">
            {author.name}
          </span>
        </li>
        <li className="post-date meta-wrapper">
          <span className="meta-icon">
            <span className="screen-reader-text">Datum</span>
            <DateIcon />
          </span>
          <span className="meta-text">{getFormattedDate(date)}</span>
        </li>
        <li className="post-update meta-wrapper">
        <span className="meta-icon">
            <span className="screen-reader-text">Aktualisiert</span>
            <ModifiedIcon />
          </span>
          <span className="meta-text">{getFormattedDate(modified)}</span>
        </li>
      </ul>
    </div>
  )
}

export default PostMeta