import React from "react"
import { graphql } from "gatsby"
import Layout from '../components/Layout.js';
import PostMeta from "../components/Postmeta.js"

 export const query = graphql`
    query($id: ID!) {
     wpgraphql {
       post(id: $id) {
        title
        content
        author {
          name
        }
        modified
        date
      }
    }
  }
`

const PostTemplate = ({ data }) => {
   const post = data.wpgraphql.post
    return (
      <Layout>
        <article>
        <div className="entry-header has-text-align-center header-footer-group">
        <div className="entry-header-inner section-inner medium">
        <h1 dangerouslySetInnerHTML={{ __html: post.title }} /> </div>
        <PostMeta modified={post.modified} author={post.author} date={post.date} />
        </div>
        <div className="post-inner thin">
        <div className="entry-content" dangerouslySetInnerHTML={{ __html: post.content }} /></div>
        </article>
      </Layout>
    )
}


export default PostTemplate